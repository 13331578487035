<template>
  <div class="upload-file upload-image">
    <a-upload class="avatar-uploader"
              v-if="!file.no"
              :file-list="fileList"
              list-type="picture-card"
              :show-upload-list="false"
              accept=".pdf"
              :customRequest="uploadCustomer"
              :before-upload="beforeUpload"
              @change="handleChange">
      <loading-outlined v-if="loading"></loading-outlined>
      <div class="pre-upload"
           v-else>
        <plus-outlined></plus-outlined><span class="mt2">上传PDF</span>
      </div>
    </a-upload>
    <div class="msg-link"
         v-else>
      <div class="link-text">
        <p class="title">{{file.name}}</p>
        <p class="abstract">{{file.size}}</p>
      </div>
      <a-image width="48px"
               height="48px"
               :src="pdfImgUrl">
      </a-image>
    </div>

    <span class="text-12 text-color-999 mt2">请在20M以内
      <a-upload class="reUpload-btn"
                v-if="Object.keys(file).length > 0"
                :file-list="fileList"
                :show-upload-list="false"
                accept=".pdf"
                :customRequest="uploadCustomer"
                :before-upload="beforeUpload"
                @change="handleChange">
        重新选择
      </a-upload>
    </span>

  </div>
</template>

<script>
import _ from "lodash";
import { Image } from "ant-design-vue";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import corpWordApi from "@/service/api/corpWord";
import channelQrcodeApi from "@/service/api/channelQrcode";
import businessRadarApi from "@/service/api/businessRadar";

export default {
  name: "UploadFile",
  components: {
    PlusOutlined,
    LoadingOutlined,
    AImage: Image,
  },
  props: ["fileInfo", "apiName"],
  data() {
    const apiList = new Map([
      ["corpWord", corpWordApi],
      ["businessRadar", businessRadarApi],
      ["channelQrcode", channelQrcodeApi],
    ]);

    return {
      fileList: [],
      file: {},
      loading: false,
      apiList,
      pdfImgUrl: process.env.VUE_APP_PDF_IMAGE_URL,
    };
  },
  watch: {
    fileInfo: {
      handler(value) {
        if (value && value.no) {
          this.file.no = value.no;
          this.file.name = value.name;
          this.file.size = this.renderSize(value.size);
        } else {
          this.file = {};
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    Api() {
      console.log(this.apiList.get(this.apiName));
      return this.apiList.get(this.apiName);
    },
  },

  methods: {
    renderSize(filesize) {
      if (null == filesize || filesize == "") {
        return "0 Bytes";
      }
      var unitArr = new Array("Bytes", "KB", "MB");
      var index = 0;
      var srcsize = parseFloat(filesize);
      index = Math.floor(Math.log(srcsize) / Math.log(1024));
      var size = srcsize / Math.pow(1024, index);
      size = size.toFixed(2); //保留的小数位数
      return size + unitArr[index];
    },
    async uploadCustomer(file) {
      this.file = {};
      const formData = new FormData();
      formData.append("file", file.file);

      try {
        const res = await this.Api.uploadPDF(formData);
        this.file.name = res.name;
        this.file.size = this.renderSize(res.size);
        this.$emit("child-image", res);
        file.onSuccess(res);
        this.loading = false;
        // this.file = {};
      } catch (error) {
        console.log(error);
        this.$message.error("上传失败！");
        this.loading = false;
      }
    },
    beforeUpload(file) {
      this.fileList = [];
      const index = file.name.lastIndexOf(".");
      const type = file.name.substring(index, file.name.length);

      const isPdf = _.toLower(type) === ".pdf";

      if (!isPdf) {
        this.$message.error("只能上传jpg或png格式文件！");
      }

      const isLt20M = file.size / 1024 / 1024 < 20;

      if (!isLt20M) {
        this.$message.error("文件大小必须小于20MB！");
      }

      return isPdf && isLt20M;
    },
    handleChange({ file, fileList }) {
      if (file.status === "uploading") {
        this.fileList = fileList;
        this.loading = true;
        return;
      }

      if (file.status === "done") {
        this.$message.success("上传成功");
        this.loading = false;
      }

      if (file.status === "error") {
        this.loading = false;
        this.$message.error("上传失败");
      }
    },
  },
};
</script>
<style  scoped>
</style>